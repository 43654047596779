@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
	3.1 Top Bar
	3.2 Header Content
	3.3 Logo
	3.4 Main Nav
	3.5 Hamburger
4. Menu
5. Section
6. Home
7. Course
8. Newsletter
9. Footer



******************************/

@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap")
  /*********************************
3.5 Hamburger
*********************************/
  .hamburger_container {
}
.hamburger {
  display: none;
  cursor: pointer;
}
.hamburger i {
  font-size: 20px;
  color: #353535;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.hamburger:hover i {
  color: #14bdee;
}

/*********************************
6. Home
*********************************/

/*.home
{
	width: 100%;
	height: 70px;
	max-height: 200px;
	background: #f2f4f5;
	border-bottom: solid 1px #edeff0;
}*/
.university {
  width: 100%;
  padding-top: 57px;
  padding-bottom: 60px;
  background: #fff;
  min-height: 235px;
  margin-top: 2.3%;
  border-top: 1px dotted #aad7f0;
}

.ugall-university {
  width: 100%;
  padding-top: 57px;
  padding-bottom: 60px;
  background: #fff;
  min-height: 190px;
  margin-top: 2.3%;
  border-top: 1px dotted #aad7f0;
}
.breadcrumbs_container {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 10%;
  width: 100%;
  padding-bottom: 13px;
  padding-left: 3px;
}
.breadcrumbs ul li {
  display: inline-block;
  position: relative;
}
.breadcrumbs ul li:not(:last-child)::after {
  display: inline-block;
  font-family: "FontAwesome";
  content: "\f105";
  margin-left: 7px;
  margin-right: 4px;
  color: #384158;
}
.breadcrumbs ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #384158;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.breadcrumbs ul li a:hover {
  color: #14bdee;
}
/*****************************************************************
#owl-theme
*****************************************************************/
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  z-index: 10000;
  background: transparent !important;
  position: absolute !important;
  top: 40% !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;
  position: absolute !important;
  top: 45% !important;
  transform: translatey(-40%) !important;
  z-index: 0 !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #1717178f !important;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav .owl-prev {
  left: -5px;
}
.owl-theme .owl-nav .owl-next {
  right: -5px;
}

/******************************/

/*********************************
7. Course
*********************************/

.university_container {
  width: 100%;
}
.university_title {
  font-family: "Karla", sans-serif;
  font-size: 40px;
  font-weight: 800;
  color: #2b2d33;
  line-height: 40px;
  height: fit-content;
}
.university_title span {
  font-size: 0.6em;
  display: block;
  color: #777373;
}

.university_info {
  width: 50%;
  height: 70px;
  margin-top: 30px;
  background: #f2f4f5;
}

.university_info_item {
  width: 49.33333333%;
  padding-left: 30px;
}
.university_info_item:not(:last-child)::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #d9dbdc;
  content: "";
}
.university_info_title {
  /*font-family: 'Roboto Slab', serif;*/
  font-size: 18px;
  font-weight: 700;
  color: #384158;
}
.university_info_text {
  margin-top: 0px;
}
.divider {
  border-right: 1px solid #d9dbdc;
  width: 1px;
  height: 30px;
}

.university_info_text a {
  font-size: 17px;
  font-weight: bold;
  color: #03a7d7;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.university_info_text a:hover {
  color: #14bdee;
}

.gelatine {
  animation: gelatine 0.5s infinite;
}
@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

.rating_r {
  margin-top: 7px;
}
.rating_r i::before {
  font-family: "FontAwesome";
  content: "\f006";
  font-style: normal;
  font-size: 36px;
  margin-right: 6px;
  color: #14bdee;
}

.course_image {
  margin-top: 21px;
  width: 100%;
}
.course_image img {
  max-width: 100%;
}
.course_tabs_container {
  width: 100%;
  margin-top: 30px;
}

/******************/

.owl-carousel.owl-theme.university_slider {
  display: block !important;
}

.university_slider .owl-stage-outer {
  /* height: 260px !important;*/
  height: 304px !important;
}

.university_education {
  padding: 17px 0;
  border: 1px solid #c9c6c6;
  border-radius: 6px;
  border-top: none;
  background: #f1f1f1;
  margin-top: 0.2rem;
}
.university_video_main {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 130px;
  height: 67px;
  z-index: 9;
  background: #1c1c1ced;
  text-align: center;
  border-top: 3px solid #ff9800;
  border-left: 3px solid #ff9800;
  border-radius: 61px 0 0 0;
}

.university_video_main i {
  color: #ebb15b;
  font-size: 3em;
  margin-top: 0;
  padding-top: 0;
}

/*******************`
***********/
.university_location {
  width: 49%;
  text-align: center;
}

.btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: linear-gradient(to right, #00bcd4 0, #d7e7fb 13%, #00bcd4 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-size: 22px;
  white-space: nowrap;
}
@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

.university_location.view_fees_popup p a {
  font-weight: bold;
}

.university_location.EMI_btn .btn {
  background: #f2f2f2;
  border: 2px solid #00bcd4;
  color: #00bcd4;
  padding: 5px 31px;
  font-weight: bold;
}
.university_location.EMI_btn .btn:hover {
  box-shadow: 0px 5px 25px rgba(29, 34, 47, 0.45);
}
.university_location.border-right {
  border-right: 2px dotted #878787;
}

.contact_university_popup {
  background: #fbfbfb;
  border: 1px solid #c5c2c2;
  padding: 20px 15px 20px 15px;
  border-radius: 5px;
}
.contact-uni_btn a {
  width: 100%;
}

.uni_monthly-offer a.btn {
  background: #25c0ec;
  width: 100%;
  padding: 18px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.uni_monthly-offer a.btn:hover,
.uni_monthly-offer a.btn:active {
  color: #fff;
  transition: ease-in-out 0.5s;
  box-shadow: inset 2px 2px 5px 1px #27558f;
  background: #3071c3;
}

.university_ADs {
  margin: 20px 0;
}

.university_ADs .img-responsive {
  width: 100%;
}
.key_box {
  text-align: center;
  border: 1px solid #cecdcd;
  background: #e7ebed;
  padding: 10px;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.key_box p {
  margin-bottom: 0;
  font-size: 14px;
  padding-top: 3%;
  line-height: 20px;
}

.summary_more .read-more-content {
  display: none;
  padding-top: 0px;
  /* float: left; */
  padding-bottom: 20px;
}
.summary_more {
  background: #fbfbfb;
  padding: 20px 15px;
  border-radius: 6px;
  border: 1px solid #c5c2c2;
}
.show-more {
  border: none;
  display: block;
  text-align: right;
  font-size: 15px;
  margin: 10px 0 0;
  color: #007bff;
  background-color: transparent;
}
.show-more:hover {
  color: #0056b3;
}

.summary-show p {
  text-align: justify;
  line-height: 22px;
  font-size: 15px;
}
.summary-show h3 {
  margin-bottom: 15px;
  margin-top: 20px;
}
.summary-show img {
  margin: 10px 0px;
}
.summary-show ul,
.summary-show ol {
  margin-left: 25px;
  width: 90%;
}
.summary-show ul li {
  list-style: disc;
  color: #252525;
  font-size: 15px;
  margin-bottom: 10px;
}
.contentss :is(.pofferone a, .pofferthree a) {
  cursor: pointer;
  color: #007bff !important;
}
.contentss :is(.pofferone a:hover, .pofferthree a:hover) {
  color: #0056b3 !important;
}

.contact-university-now {
  padding: 20px;
  background: #fbfbfb;
  border: 1px solid #c5c2c2;
  margin-bottom: 15px;
  border-radius: 6px;
  width: 100%;
}
.headingss {
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
  color: #404040;
  margin-left: 0% !important;
}
.contentss {
  padding: 10px;
  border-bottom: 1px dashed #ccc;
  color: #252525;
  font-size: 15px;
  margin-left: 0% !important;
}
.contentss:last-child {
  border: none;
}

.faculty_details {
  background: #fbfbfb;
  padding: 20px 15px;
  border-radius: 6px;
  border: 1px solid #c5c2c2;
}
.faculty_details p {
  font-size: 15px;
  line-height: 22px;
}

/*#modalLoginImg {
    width: 80%;
    margin: 0 auto;
}*/
.connsult-wrap.corseimg-popup {
  width: 100%;
  margin: 0 auto;
}
.slide-img {
  height: 125px;
  position: relative;
}
.faculty-box {
  background: #f3f6f6;
  border: 1px solid #f0eeee;
  padding: 10px;
  border-radius: 6px;
  border-bottom: 4px solid #343434;
  box-shadow: 0px 3px 3px 1px #7171713b;
  height: 348px;
}
.faculty-bg {
  position: absolute;
  top: 0;
  z-index: ;
  opacity: 0.2;
  rotate: -5deg;
  width: 100%;
}
.slide-img {
  height: 125px;
  position: relative;
}
.slide-img img {
  width: 100%;
  object-fit: cover;
  box-sizing: border-box;
  position: absolute;
  padding: 20px;
}
.faculty {
  text-align: center;
  padding: 50px 0;
  font-size: 19px;
}
.detail-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 12px;
  box-sizing: border-box;
  background: #f0f0f0;
  border-radius: 5px;
}
.type {
  display: flex;
  flex-direction: column;
}
.detail-box .type .university-name {
  display: -webkit-box;
  font-weight: 500;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #252525;
  font-size: 14px;
}
#faculty_university .owl-next {
  right: -25px;
}

#e_loan a {
  height: 60px;
  word-break: break-word;
  line-height: 28px;
  color: #fff;
  background: #0d6efd;
  font-size: 1.3rem;
  padding: 10px;
  text-align: center;
  border-radius: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100%;
  border: 5px solid #fff;
  transition: ease-in-out 0.8s;
}
#e_loan a:hover {
  border: 5px solid #649ff7;
}

/*********************************
7. Course
*********************************/

.course {
  width: 100%;
  padding-top: 57px;
  padding-bottom: 50px;
  background: #ffffff;
}
.course_container {
  width: 100%;
}
.course_title {
  font-family: "Roboto Slab", serif;
  font-size: 36px;
  font-weight: 700;
  color: #384158;
}
.course_info {
  width: 100%;
  height: 92px;
  margin-top: 68px;
  background: #f2f4f5;
}
.course_info_item {
  width: 33.33333333%;
  padding-left: 30px;
}
.course_info_item:not(:last-child)::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #d9dbdc;
  content: "";
}
.course_info_title {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: 700;
  color: #384158;
}
.course_info_text {
  margin-top: 8px;
}
.course_info_text a {
  font-size: 14px;
  color: #384158;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.course_info_text a:hover {
  color: #14bdee;
}
.rating_r {
  margin-top: 7px;
}
.rating_r i::before {
  font-family: "FontAwesome";
  content: "\f006";
  font-style: normal;
  font-size: 30px;
  margin-right: 4px;
  color: #14bdee;
}
.rating_r_1 i:first-child::before {
  content: "\f005";
}
.rating_r_2 i:first-child::before {
  content: "\f005";
}
.rating_r_2 i:nth-child(2)::before {
  content: "\f005";
}
.rating_r_3 i:first-child::before {
  content: "\f005";
}
.rating_r_3 i:nth-child(2)::before {
  content: "\f005";
}
.rating_r_3 i:nth-child(3)::before {
  content: "\f005";
}
.rating_r_4 i:first-child::before {
  content: "\f005";
}
.rating_r_4 i:nth-child(2)::before {
  content: "\f005";
}
.rating_r_4 i:nth-child(3)::before {
  content: "\f005";
}
.rating_r_4 i:nth-child(4)::before {
  content: "\f123";
}
.rating_r_5 i:first-child::before {
  content: "\f005";
}
.rating_r_5 i:nth-child(2)::before {
  content: "\f005";
}
.rating_r_5 i:nth-child(3)::before {
  content: "\f005";
}
.rating_r_5 i:nth-child(4)::before {
  content: "\f005";
}
.rating_r_5 i:nth-child(5)::before {
  content: "\f005";
}
.course_image {
  margin-top: 21px;
  width: 100%;
}
.course_image img {
  max-width: 100%;
}
.course_tabs_container {
  width: 100%;
  margin-top: 30px;
}
.tab {
  height: 40px;
  background: #f2f4f5;
  font-size: 16px;
  color: #384158;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 10px;
  padding-left: 21px;
  padding-right: 20px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.tab.active,
.tab:hover {
  background: #14bdee;
  color: #ffffff;
}
.tab_panels {
}
.tab_panel {
  display: none !important;
  width: 100%;
  height: 100%;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
  border: solid 1px #ebebeb;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 25px;
}
/* Fade in tabs */
@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tab_panel.active {
  display: block !important;
}
.tab_panel_title {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 700;
  color: #384158;
}
.tab_panel_text {
  margin-top: 10px;
}
.tab_panel_text p {
  font-size: 16px;
  font-weight: 400;
  color: #76777a;
  line-height: 1.75;
}
.tab_panel_section {
  margin-top: 17px;
}
.tab_panel_subtitle {
  font-size: 18px;
  font-weight: 400;
  color: #384158;
}
.tab_panel_bullets {
  margin-top: 9px;
}
.tab_panel_bullets li {
  position: relative;
  padding-left: 27px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #76777a;
}
.tab_panel_bullets li:not(:last-child) {
  margin-bottom: 2px;
}
.tab_panel_bullets li::before {
  display: block;
  position: absolute;
  top: 9px;
  left: 1px;
  width: 7px;
  height: 7px;
  background: #76777a;
  content: "";
  border-radius: 50%;
}
.tab_panel_faq {
  margin-top: 51px;
}
.elements_accordions {
  margin-top: 34px;
}

.University_Faq button {
  text-align: left;
  padding: 6px 0;
  text-decoration: none;
  font-size: 17px;
  color: #252525;
  border: none;
  background: transparent;
}
.University_Faq button:hover {
  text-decoration: none;
  color: #252525;
}
.University_Faq .card-body {
  background: #f5f5f5 !important;
}
.University_Faq .card-body ul {
  color: #252525;
}
.University_Faq .card-body ul li {
  list-style: disc;
  margin-bottom: 6px;
}

.accordion_container:not(:last-child) {
  margin-bottom: 12px;
}
.accordion {
  width: 96%;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.accordion .card-header {
  max-width: 100%;
  overflow: hidden;
  /*white-space: nowrap;*/
  font-size: 18px;
  font-weight: bold;
  color: #384158;
  background: #f5f5f500 !important;
  padding: 18px 15px;
}
.University_Faq .card {
  border-bottom: none;
}

.University_Faq .card:last-child {
  border-bottom: 1px solid #c4c4c4;
}
.faq-btn a.card-title {
  width: 96.5%;
  display: block;
}
.University_Faq .collapsible-link::before {
  content: "\f107";
  position: absolute;
  top: 50%;
  right: -0.2rem !important;
  transform: translateY(-50%);
  display: block;
  font-family: "FontAwesome";
  font-size: 1.3rem;
}

/*
.accordion::before
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -1px;
	left: 0;
	height: 100%;
	font-family: 'FontAwesome';
	content: '\f105';
	font-size: 30px;
	color: #384158;
	font-weight: 400;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}*/

.accordion.active::before {
  content: "\f107";
}
.accordion_panel {
  padding-right: 8px;
  padding-left: 38px;
  padding-top: 9px;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion.active + .accordion_panel {
  margin-bottom: 20px;
}
.accordion_panel p {
  font-size: 16px;
  font-weight: 400;
  color: #76777a;
  line-height: 1.75;
}
.tab_panel_2 {
  padding-bottom: 10px;
}
.dropdowns {
  margin-top: 17px;
}
.dropdowns li ul {
  max-height: 0;
  padding-left: 30px;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.dropdowns li {
  position: relative;
}
.dropdowns li.has_children > .dropdown_item {
  cursor: pointer;
}
.dropdown_item {
  padding-top: 26px;
  padding-bottom: 25px;
}
.dropdowns li:not(:last-child) .dropdown_item {
  border-bottom: solid 1px #e5e5e5;
}
.dropdown_item_title {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 700;
  color: #384158;
  padding-left: 24px;
}
.dropdown_item_title span {
  font-size: 18px;
}
.dropdown_item_title::before {
  position: absolute;
  top: 1px;
  left: 0;
  font-family: "FontAwesome";
  content: "\f0f6";
  font-size: 18px;
  color: #384158;
  opacity: 0.65;
}
.has_children > .dropdown_item > .dropdown_item_title::after {
  position: absolute;
  top: -10px;
  right: 0;
  font-family: "FontAwesome";
  content: "\f105";
  font-size: 30px;
  font-weight: 400;
  color: #384158;
}
.has_children.active > .dropdown_item > .dropdown_item_title::after {
  content: "\f107";
}
.dropdown_item_text {
  margin-top: 10px;
}
.dropdown_item_text p {
  font-size: 16px;
  font-weight: 400;
  color: #76777a;
  line-height: 1.75;
}
.tab_panel_3 {
  padding-bottom: 42px;
}
.review_rating_container {
  margin-top: 43px;
}
.review_rating {
  display: inline-block;
  text-align: center;
  padding-left: 35px;
  padding-top: 28px;
  vertical-align: top;
}
.review_rating_num {
  font-size: 72px;
  line-height: 0.75;
  font-weight: 900;
  color: #384158;
}
.review_rating_stars {
  margin-top: 27px;
  margin-left: 3px;
}
.review_rating_stars .rating_r i::before {
  color: #ffc80a;
  font-size: 18px;
}
.review_rating_text {
  font-size: 16px;
  color: #b5b8be;
  margin-top: 12px;
}
.review_rating_bars {
  display: inline-block;
  padding-left: 134px;
}
.review_rating_bars ul li:not(:last-child) {
  margin-bottom: 10px;
}
.review_rating_bars ul li span {
  font-size: 16px;
  font-weight: 400;
  color: #384158;
  vertical-align: middle;
}
.review_rating_bar {
  display: inline-block;
  vertical-align: middle;
  width: 300px;
  height: 30px;
  background: #efefef;
  margin-left: 22px;
}
.review_rating_bar > div {
  height: 100%;
  background: #14bdee;
}
.comments_container {
  margin-top: 80px;
}
.comment_item {
  border-bottom: solid 1px #e5e5e5;
  padding-top: 31px;
  padding-bottom: 16px;
}
.comment_image div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}
.comment_image div img {
  max-width: 100%;
}
.comment_content {
  width: 100%;
  padding-left: 20px;
  margin-top: -7px;
}
.comment_author::after {
  display: inline-block;
  content: "-";
  margin-left: 6px;
}
.comment_author a {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: 700;
  color: #384158;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.comment_author a:hover {
  color: #14bdee;
}
.comments_container .rating_r {
  margin-top: 3px;
  margin-left: 5px;
}
.comments_container .rating_r i::before {
  color: #ffc80a;
}
.comment_text {
  margin-top: 12px;
}
.comment_extras {
  margin-top: 13px;
}
.comment_reply {
  margin-left: 35px;
}
.comment_extra a {
  font-size: 14px;
  font-weight: 400;
  color: #76777a;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.comment_extra a:hover {
  color: #14bdee;
}
.comment_extra a span {
  margin-left: 3px;
}
.comment_reply a i {
  color: #14bdee;
}
.comments_list li ul {
  padding-left: 99px;
}
.add_comment_container {
  margin-top: 24px;
}
.add_comment_title {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 700;
  color: #384158;
}
.add_comment_text {
  margin-top: 14px;
}
.add_comment_text a {
  color: #14bdee;
  text-decoration: underline;
}
/************/
.specializ-box {
  background: #f0f0f0;
  border-radius: 8px;
}
.specializ-img img {
  border-radius: 10px 10px 0 0;
}

.spec-tital-box {
  text-align: center;
  padding: 15px 10px;
}
.spec-tital-box .title a {
  color: #2b568c;
  font-weight: 600;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
}
.Specialization-list .owl-next {
  right: -22px !important;
}

/*********************************
8. Sidebar
*********************************/

.sidebar {
  width: 100%;
  padding-left: 10px;
  padding-top: 0px;
}
.sidebar_section:not(:last-child) {
  margin-bottom: 35px;
}
/*.sidebar_section_title
{
	
	font-size: 24px;
	font-weight: 700;
	color: #384158;
	line-height: 0.75;
}*/
.sidebar_feature {
  margin-top: 33px;
}
.course_price {
  font-size: 24px;
  font-weight: 400;
  color: #14bdee;
}
.feature_list {
  margin-top: 16px;
}
.feature:not(:last-child) {
  margin-bottom: 21px;
}
.feature_title {
  padding-left: 31px;
}
.feature_title i {
  position: absolute;
  top: calc(50% - 1px);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  font-size: 18px;
  color: #14bdee;
}
.uc-content {
  background: #fff;
  padding: 15px;
  border: 2px solid #2baee8;
  border-radius: 6px;
}

span.call_side a,
span.call_side i {
  background: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #01adf3 100%
  );
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-size: 22px;
  white-space: nowrap;
  font-weight: 900;
}
.uc-content p {
  line-height: 22px;
  font-size: 15px;
  margin: 10px 0;
}

.uc-content ul {
  color: #252525;
  list-style: outside;
  font-size: 15px;
}
h4.heading-blue {
  font-size: 1.3em;
  font-style: italic;
}
.ads_sidebar img {
  border-radius: 5px;
}
.sidebar_teacher {
  margin-top: 40px;
}
.teacher_image {
  width: 102px;
  height: 102px;
}
.teacher_image img {
  max-width: 100%;
}
.teacher_title {
  padding-left: 35px;
}
.teacher_position {
  font-size: 14px;
  color: #76777a;
  margin-top: 4px;
}
.teacher_name a {
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  font-weight: 700;
  color: #384158;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.teacher_name a:hover {
  color: #14bdee;
}
.teacher_meta_container {
  margin-top: 27px;
}
.teacher_meta:not(:last-child) {
  margin-bottom: 9px;
}
.teacher_meta_title,
.teacher_meta_text span {
  font-size: 14px;
  font-weight: 400;
  color: #76777a;
}
.teacher_meta_text span {
  margin-right: 4px;
}
.teacher_meta_text i {
  color: #14bdee;
}
.teacher_info {
  margin-top: 17px;
}
.teacher_info p {
  line-height: 2;
}

.sidebar_latest {
  margin-top: 31px;
  background: #f2f2f2;
  padding: 10px 9px;
}
.latest {
  padding: 10px 8px;
  background: #ffffff;
  border-radius: 6px;
}

.latest:not(:last-child) {
  margin-bottom: 10px;
}
.latest_image div {
  width: 90px;
  height: 74px;
  border-radius: 3px;
  overflow: hidden;
}
.latest_image {
  margin-top: 4%;
}
.latest_image img {
  max-width: 100%;
}
.latest_content {
  padding-left: 13px;
  margin-top: 0px;
  height: 75px;
}

.latest_title a {
  font-size: 16px;
  font-weight: 700;
  color: #383749;
  line-height: 1.625;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.latest_title a:hover {
  color: #14bdee;
}
.latest_buyers {
  font-size: 15px;
  font-weight: normal;
  color: #252525;
  line-height: 22px;
  margin-top: 8px;
}
.latest_title {
  font-weight: bold;
  color: #252525;
}

/*************************/
.Unversity_courses_Wrapp {
  margin-bottom: 50px;
}
.unicourse-logo img {
  margin: 0px auto;
}
.unicourse-logo {
  position: absolute;
  z-index: 9;
  width: 25%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  top: 2.2%;
  left: 6%;
  padding: 4px;
}
.courses_image img {
  border-radius: 7px;
}

.cour-university_body {
  padding: 12px 5px 5px;
}
.cour-university_title {
  font-size: 16px;
}
span.course-amt {
  font-size: 15px;
  color: #252525;
  display: block;
  margin: 6px 0;
}
.btn.view-fees {
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #e5e3e3;
  background: #fff;
}
.details-footer {
  padding: 6px 10px;
  text-align: left;
  background: #59586e;
}
.details-footer a {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.course_body {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  background: #f2f1f1;
}

/************Course Manaase **********/
.internal_box.student-box {
  margin-top: 18%;
  height: 250px;
  background: #f4f4f4;
  border: 2px dashed #a29f9f;
  color: #4b4a4a;
}

.std_img {
  height: 70px;
  text-align: center;
  margin: -26% auto 3%;
  width: 70px;
}

.std_img img {
  border-radius: 50%;
  border: 3px solid #222;
}
.internal_box.student-box h6.student-mannse-Title {
  color: #252525;
  margin: 9px auto 0;
}
.batch-time {
  height: 30px;
  background: #ffffff;
  width: 100%;
  vertical-align: middle;
  border-radius: 10px;
  padding: 5px 8px;
}
.batch-time p {
  margin-bottom: 0;
  padding: 5px;
  font-size: 14px;
  color: #939393;
}

.internal_box.student-box .Blog-View-Desc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
}
#university_stdt_mannseblog .owl-next {
  right: 18px;
}

/**************/
#Admission_procedure ul li span {
  color: #fff !important;
}
#Loan_emi span {
  color: #fff !important;
}
.review_form_col {
  margin: 0 auto;
  width: 100% !important;
}
.review_form .form_title {
  font-weight: bold;
}

.review_form .form-control {
  color: #252525 !important;
  border: 1px solid #847e7e;
  padding: 11px 15px;
  height: calc(2.6rem + 2px) !important;
}

.review_form textarea.form-control {
  min-height: calc(4em + 0.75rem + calc(var(--bs-border-width) * 2));
}

/******************************************************************************************************************/
/******************************************************************************************************************/
/*# COMPARE CSS*/
/******************************************************************************************************************/
/******************************************************************************************************************/

.compair_wrap_breadcrumb {
  width: 100%;
  background: #fff;
  margin-top: 2%;
  border-top: 1px dotted #aad7f0;
  height: 130px;
  position: relative;
}
.compare_details {
  padding: 20px 0;
}

.compare-box {
  position: relative;
  background: #f4f4f4;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  text-align: center;
  padding: 20px 10px;
}
.compare-img {
  margin-bottom: 10px;
}
.compare-img img {
  max-width: 140px;
}

.compare-text {
  font-weight: 700;
  font-size: 17px;
}

.compare-remove {
  position: absolute;
  right: -9px;
  top: -13px;
  cursor: pointer;
}
.compare-remove i {
  font-size: 20px;
  color: #5d5d5d;
  /* cursor: pointer; */
}
.compare-add-btn {
  font-size: 18px;
  color: rgba(37, 56, 88, 0.7);
  background-color: rgb(213 213 213) !important;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  line-height: 31px;
  cursor: pointer;
  margin-bottom: 8px;
}
.compare_feilds ul.compare-list .edu-mode {
  font-size: 14px;
  font-weight: bold;
}

ul.compare-list {
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.compare_feilds {
  background: #f2f2f2;
  padding: 20px 15px;
  border-radius: 6px;
}
.compare_feilds .edu-mode {
  min-height: 90px;
  font-size: 15px;
  padding: 15px 12px;
  /*line-height: 45px;*/
  border-bottom: 1px dotted #ddd;
  list-style: none;
}
.compare_feilds.compare_values {
  background: #fdfdfd;
  border: 2px solid #f0eaea;
  box-shadow: 0px 0px 12px 1px #949494;
  text-align: center;
}

.compare_feilds.compare_values .edu-mode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

ul.compare-values-list {
  padding-left: 0;
}
li.edu-mode.sample-certificate {
  min-height: 180px;
}
.edu-mode.sample-certificate img {
  width: 60%;
}
/*.students-enrolled{
	 display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #111116;
  height: 100vh;
  align-items: center;
}*/
.radialProgressBar {
  border-radius: 50%;
  -webkit-transform: translate(50%, 50%);
  transform: translate(4%, 0);
  width: 55px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  background: #ddd;
  margin: 0 auto;
  /* float: left; */
}

.radialProgressBar .overlay {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  background: #fff;
  text-align: center;
  padding-top: 26%;
}

.progress-70 {
  background-image: -webkit-linear-gradient(left, #028cd5 50%, transparent 50%),
    -webkit-linear-gradient(72deg, #028cd5 50%, #ddd 50%);
  background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
    linear-gradient(18deg, #028cd5 50%, #ddd 50%);
}
.progress-80 {
  background-image: -webkit-linear-gradient(left, #028cd5 50%, transparent 50%),
    -webkit-linear-gradient(108deg, #028cd5 50%, #ddd 50%);
  background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
    linear-gradient(-18deg, #028cd5 50%, #ddd 50%);
}
.yes-value span {
  border: 1px dashed #f75d34;
  padding: 10px 10px;
  position: relative;
}
.yes-value span:before {
  content: "";
  position: absolute;
  top: -7px;
  right: -7px;
  background-image: url(../img/yes.png);
  width: 16px;
  height: 16px;
  background-size: 17px;
}
li.edu-mode.approvals {
  word-break: break-all;
  line-height: 14px;
}

.university.best_colleges {
  /*  min-height: 100px;
    padding-bottom: 50px;
    margin-top: 1.2%;*/

  min-height: auto;
  padding: 0;
  margin-top: 0;
  padding-top: 0;
}
.breadcrumbs_container.Allcourses_crumbs {
  position: relative;
  padding: 20px 0;
  min-height: 100px;
}
.university.best_colleges .university_title {
  padding-left: 0.7%;
  color: #1b5bac;
  font-size: 1.7em;
  border-left: 5px double #ff9800;
  display: inline-block;
}
/*******Online MBA Courses*************/

.best_college_full {
  box-shadow: -1px 1px 6px 2px #2222222b;
  border-radius: 6px;
  padding: 25px;
  margin-bottom: 35px;
}
.trends-title {
  font-size: 16px;
  font-weight: 700;
}
.trends-img img {
  border-radius: 8px;
}
ul.university-details {
  padding-left: 0;
}

.university-details li {
  margin: 14px 0px;
  border-bottom: 1px dotted #c8c8c8;
  padding-bottom: 8px;
}
ul.university-details li:last-child {
  border-bottom: none;
}

a.watch_video {
  text-align: center;
  margin: 0 15px;
  border: 2px solid #14bdee;
  padding: 10px 15px;
  border-radius: 15px;
  color: #252525;
  transition: ease-in-out 0.5s;
}
a.watch_video:hover {
  box-shadow: 0px 2px 7px 2px #175675ab;
}
.ol-detail-box {
  padding-left: 5%;
}

.college_summary {
  border: 2px solid #19cbff;
  padding: 25px 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  background: #eaf5fb;
  font-size: 15px;
}
a.color-blue.top-uni-more {
  text-align: right;
  display: block;
  font-weight: 700;
}

/******************************#general-specialization-course#*****************************/
ul li {
  margin-left: 0;
  list-style: disc;
  font-size: 15px;
  margin-bottom: 10px;
}
.specialization_wrap {
  width: 75%;
  margin: 0 auto;
}
.general_width {
  width: auto;
  margin: 0 auto;
}
.specialization_info {
  background: #f0f0f0;
}
.border-radius {
  border-radius: 6px !important;
}

span.info-btn {
  font-size: 12px;
}
.update-col span {
  font-size: 12px;
}
.general_form-Wrap small {
  font-size: 0.8em;
}

.special_boxes {
  padding: 20px 30px;
  box-shadow: -1px 1px 6px 2px #2222222b;
  border-radius: 12px;
  margin-bottom: 35px;
}
.gsc-title-wrap h2.modal-title {
  font-size: 1.5em;
}
.slide-track {
  width: 100%;
  display: flex;
  gap: 1.2em;
  overflow: hidden;
}

.logo-slider {
  margin-top: 10px;
  background-color: whitesmoke;
  padding: 1.2em 0em;
}
.slide img {
  width: 100px;
  height: auto;
  animation: scroll 60s linear infinite;
  background: #fff;
  padding: 9px;
  border-radius: 6px;
  box-shadow: inset 1px -1px 5px 1px #dfdddd;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-700%);
  }
}

.general-from-wrapper {
  background: #eaf0f3;
  padding: 12px 12px;
  border-radius: 10px;
}
.spec-title {
  margin: 3em 0;
  background: #e8e8e8;
  padding: 15px 10px;
  border-radius: 10px;
}
.spec-title h4 {
  font-size: 1.2em;
  line-height: 24px;
  color: #27558f;
}

.block {
  position: relative;
  /*margin: 300px auto 0;
  width: 500px;
  height: 250px;*/
  background: linear-gradient(0deg, #ffffff, #ffffff);
}

.block:before,
.block:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, #e8e8e8, #4e5db3, #5e5e5e, #4e5db3);
  border-radius: 10px;
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.block:after {
  filter: blur(2px);
}

h5.main-head.seotag {
  font-size: 1.2em;
}
.spec-table .table th {
  font-size: 13px;
  background: #c9e4f1;
}
.spec-table .table tr {
  font-size: 13px;
}
.spec-table .table.table-bordered {
  border: 1px solid #a5c4d2;
}

.spec-table .table th i {
  color: #088eb5;
  font-size: 15px;
  padding-right: 5px;
}

.spec-le-img {
  position: relative;
}

.spec-le-img img {
  z-index: 0;
  position: absolute;
  width: 8em;
  right: 0;
  left: 0;
  top: -32px;
  margin: auto;
}

.gererl-box {
  margin-bottom: 10px;
}

.gererl-box .form-check .form-check-input {
  margin-left: 0;
}
.gererl-box .form-check {
  border: 2px solid #14bdee;
  padding: 6px 12px;
  border-radius: 3px;
}

.gererl-box .form_title {
  margin-bottom: 0;
  display: inline-block;
  background: #14bdee;
  padding: 6px 10px;
  font-size: 15px;
  color: #fff;
}
.gererl-box .comment_input,
.gererl-box .form-control,
.gererl-box .counter_input {
  border: 2px solid #14bdee;
  margin-top: -1px;
  padding: 0.375rem 0.75rem;
  border-radius: 3px;
  height: 35px;
}
.general_button {
  width: 250px;
  height: 46px;
  background: #14bdee;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 3px;
  margin-top: 8px;
}
.general_button:hover {
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.25);
}
.specialization_info p {
  text-align: justify;
  line-height: 22px;
  font-size: 15px;
}
.specialization_info h3 {
  margin-bottom: 15px;
  margin-top: 20px;
}
.specialization_info ul {
  margin-left: 10px;
  margin-top: 15px;
}
.specialization_info ul li {
  list-style: disc;
  color: #252525;
  font-size: 15px;
  margin-bottom: 10px;
}
.specialization_info img {
  margin: 10px 0 20px;
}

.confused_wrap {
  background: #e6eaed;
  padding-top: 20px;
}
.advice_wrap #e_loan a {
  height: 66px;
  line-height: 34px;
  word-break: break-word;
  padding: 10px 22px;
  width: auto;
  transition: ease-in-out 0.8s;
}

.custom-course-wrap table {
  margin-bottom: 20px;
  width: 100% !important;
}
.custom-table-wrap table tr,
.custom-table-wrap table td {
  padding: 6px;
}

.custom-table-wrap table tr td p {
  padding: 8px;
}

/******************************/
.university.expert_title {
  min-height: auto;
  padding-bottom: 30px;
}

/**************/

.grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 0em 0 4em;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0px 1% 10px;
  min-width: 100%;
  max-width: 480px;
  max-height: 360px;
  width: 48%;
  height: auto;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h4 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h4 span {
  font-weight: 800;
}

.grid figure h4,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*-----------------*/
/***** Terry *****/
/*-----------------*/

figure.effect-terry {
  background: #34495e;
}

figure.effect-terry figcaption {
  padding: 1em;
}

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
  position: absolute;
  width: 200%;
  height: 200%;
  border-style: solid;
  border-color: #101010;
  content: "";
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-terry figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 40px 35px 0;
  -webkit-transform: translate3d(70px, 60px, 0);
  transform: translate3d(70px, 60px, 0);
}

figure.effect-terry figcaption::after {
  top: 0;
  left: 0;
  border-width: 15px 0 0 15px;
  -webkit-transform: translate3d(-15px, -15px, 0);
  transform: translate3d(-15px, -15px, 0);
}

figure.effect-terry img,
figure.effect-terry p a {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-terry img {
  opacity: 0.85;
}

figure.effect-terry h4 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.4em 10px;
  width: 50%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  background: #030303;
  color: #fff;
}

@media screen and (max-width: 920px) {
  figure.effect-terry h4 {
    padding: 0.75em 10px;
    font-size: 120%;
  }
}

figure.effect-terry p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
  font-size: 111%;
}

figure.effect-terry p a {
  display: block;
  margin-bottom: 1em;
  color: #fff;
  opacity: 0;
  -webkit-transform: translate3d(90px, 0, 0);
  transform: translate3d(90px, 0, 0);
}

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
  color: #f3cf3f;
}

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-terry:hover img {
  opacity: 0.6;
}

figure.effect-terry:hover h4,
figure.effect-terry:hover p a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-terry:hover p a {
  opacity: 1;
}

figure.effect-terry:hover p a:first-child {
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s;
}

figure.effect-terry:hover p a:nth-child(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-terry:hover p a:nth-child(3) {
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

figure.effect-terry:hover p a:nth-child(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.Expert_info {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 35px;
}
.expert_wrapper {
  margin-top: 1%;
  padding-top: 2%;
  border-top: 1px dotted #aad7f0;
}

.exp_col {
  padding: 7px 7px;
  border: 1px solid #d9d7d7;
  border-radius: 6px;
  background: #f5f5f5;
  margin-top: 0.2rem;
}
.exprt-des {
  width: 49%;
  text-align: center;
}
.exprt-des p {
  margin-bottom: 0;
}
.exprt-des.border-right {
  border-right: 2px dotted #878787;
}
.achive-col {
  padding: 20px;
}
.achive-col:first-child {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.achive-col:last-child {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

/***/
.content-section {
  min-height: 2000px;
}

.sidebar-section {
  position: absolute;
  height: 100%;
  width: 100%;
}

.sidebar-item {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 15px 4px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.make-me-sticky {
  position: sticky;
  top: 0;
  padding: 0 5px;
}
.scrollbox {
  margin: 0 auto;
  padding: 5px 0;
  width: 100%;
  /* height: 100vh;*/

  position: relative;
  /* overflow: hidden;
  background-color: #FFF;*/
}

.scrollgroup {
  position: relative;
  width: 100%;
  height: 100vh !important ;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
  padding-left: 0;
}

.scrollgroup .scrollitem {
  width: 100%;
  padding: 0;
  margin: 0 auto 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.other-expert-row {
  margin-bottom: 15px;
  padding: 35px 6px !important;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  box-shadow: 1px 2px 4px 1px #b6b5b585;
}

.ot-img {
  width: 25%;
}
.ot-ex-details {
  width: 75%;
  margin-left: 10px;
}
.other-expert-img {
  border-radius: 50%;
  padding: 1px;
  background: #ccc;
}
ul.other-career-expert {
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}
ul.other-career-expert li {
  margin: 5px;
  font-size: 12px;
  font-weight: 700;
  list-style: none;
}
ul.other-career-expert li a {
  color: #252525;
}
a.expt-name {
  font-weight: bold;
}

.expert_form .input-group-text {
  padding: 10px 12px;
  border-radius: 4px 0 0 4px;
  height: 100%;
}

.expert_form .form-group {
  margin-bottom: 0.3rem;
}

/**.expert-owl-carousel {
  max-width: 320px;
}**/

/*.exprt-des .rating_r_4 i:nth-child(3)::before {
    content: "\f123";
}
.exprt-des .rating_r_4 i:nth-child(4)::before {
    content: "\f006";
}*/

/********************************************************************************
#Blogs
***********************************************************************************/

.university.Blogs_title {
  min-height: auto;
  padding-bottom: 40px;
}

/*********************************
7. Blog
*********************************/

.blog {
  width: 100%;
  background: #ffffff;
  padding-top: 00px;
  padding-bottom: 20px;
}
.blog_content {
  width: 100%;
}
.blog_title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  color: #384158;
}
.blog_meta {
  margin-top: 14px;
}
.blog_meta ul li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #b5b8be;
  list-style: none;
}
.blog_meta ul li a {
  font-size: 14px;
  color: #b5b8be;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_meta ul li a:hover {
  color: #14bdee;
}
.blog_meta ul li:not(:last-child)::after {
  display: inline-block;
  content: "|";
  margin-left: 14px;
  margin-right: 14px;
}
.blog_image {
  width: 100%;
  margin-top: 18px;
}
.blog_image img {
  max-width: 100%;
}
.blog_content p {
  font-size: 16px;
  line-height: 1.75;
  color: #76777a;
  margin-top: 29px;
  margin-bottom: 0;
}
.blog_quote {
  margin-top: 24px;
  margin-bottom: -2px;
}
.blog_quote i {
  font-size: 30px;
  color: #14bdee;
}
.blog_quote div {
  font-size: 18px;
  font-style: italic;
  line-height: 1.66;
  color: #384158;
  padding-left: 36px;
}
.blog_subtitle {
  font-size: 24px;
  color: #384158;
  line-height: 1.2;
  margin-top: 48px;
}
.blog_images {
  margin-top: 31px;
  margin-bottom: 35px;
}
.blog_image_small {
  width: 100%;
}
.blog_image_small img {
  max-width: 100%;
}
.blog_extra {
  margin-top: 39px;
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 18px;
}
.blog_tags span {
  color: #384158;
}
.blog_tags ul {
  display: inline-block;
  margin-left: 3px;
}
.blog_tags ul li {
  display: inline-block;
}
.blog_tags ul li a {
  font-size: 14px;
  color: #b5b8be;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_tags ul li a:hover {
  color: #14bdee;
}
.blog_social span {
  font-size: 14px;
  color: #384158;
}
.blog_social ul {
  display: inline-block;
  margin-left: 0px;
}
.blog_social ul li {
  display: inline-block;
}
.blog_social ul li:not(:last-child) {
  margin-right: 10px;
}
.blog_social ul li i {
  font-size: 17px;
  color: #686868;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_social ul li:hover i {
  color: #14bdee;
}
.comments_container {
  margin-top: 51px;
}
.comments_title {
  font-size: 24px;
  font-weight: 700;
  color: #2c3145;
}
.comments_list li:not(:last-child) .comment_item {
  border-bottom: solid 1px #e5e5e5;
}
.comment_item {
  padding-top: 31px;
  padding-bottom: 16px;
}
.comment_image div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}
.comment_image div img {
  max-width: 100%;
}
.comment_content {
  width: 100%;
  padding-left: 20px;
  margin-top: -7px;
}
.comment_author::after {
  display: inline-block;
  content: "-";
  margin-left: 6px;
}
.comment_author a {
  font-size: 18px;
  font-weight: 700;
  color: #384158;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.comment_author a:hover {
  color: #14bdee;
}
.comments_container .rating_r {
  margin-top: 3px;
  margin-left: 5px;
}
.comments_container .rating_r i::before {
  color: #ffc80a;
}
.comment_text {
  margin-top: 12px;
}
.comment_extras {
  margin-top: 13px;
}
.comment_likes::after {
  display: inline-block;
  content: "|";
  margin-left: 15px;
  margin-right: 15px;
}
.comment_reply {
}
.comment_extra a {
  font-size: 12px;
  font-weight: 400;
  color: #5e6271;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.comment_extra a:hover {
  color: #14bdee;
}
.comment_extra a span {
  margin-left: 3px;
}
.comments_list li ul {
  padding-left: 99px;
}
.add_comment_container {
  margin-top: 31px;
}
.add_comment_title {
  font-size: 24px;
  font-weight: 700;
  color: #384158;
}
.add_comment_text {
  margin-top: 14px;
}
.sidebar_section:not(:last-child) {
  margin-bottom: 60px;
}
.sidebar_section_title {
  font-size: calc(1.1rem + 0.45vw);
  font-weight: 700;
  color: #384158;
  line-height: 0.75;
}
.sidebar_categories {
  margin-top: 16px;
}

.sidebar_talk-expert {
  background: #f2f2f2;
  padding: 10px;
  margin-top: 20px;
}

ul.categories_list {
  padding-left: 0;
  height: 500px;
  overflow: auto;
}

.sidebar_categories ul li {
  position: relative;
  width: 100%;
  height: 51px;
  list-style: none;
}
.sidebar_categories ul li:not(:last-of-type) {
  border-bottom: solid 1px #e5e5e5;
}
.sidebar_categories ul li a {
  display: block;
  position: relative;
  font-size: 14px;
  color: #b5b8be;
  line-height: 51px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.sidebar_categories ul li:hover a,
.sidebar_categories ul li:hover a::before {
  color: #14bdee;
}
.categories_list li a span {
  float: right;
}
.sidebar_latest {
  margin-top: 31px;
}
.latest:not(:last-child) {
  margin-bottom: 20px;
}
.sidebar_section .sidebar_latest.Single_Blogs .latest_content {
  padding-left: 10px;
  margin-top: 0px;
  height: auto;
}

.latest_image div {
  width: 90px;
  height: 74px;
  border-radius: 3px;
  overflow: hidden;
}
.latest_image div img {
  max-width: 100%;
}
.sidebar_section .latest_content {
  padding-left: 10px;
  margin-top: 0px;
  min-height: 80px;
}
.sidebar_section .latest_title a {
  font-size: 13px;
  font-weight: 700;
  color: #383749;
  line-height: 1.625;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.latest_title a:hover {
  color: #14bdee;
}
.latest_date {
  font-size: 12px;
  color: #9fa1a3;
  margin-top: 10px;
  text-transform: uppercase;
}
.sidebar_gallery {
  margin-top: 34px;
}
.gallery_item {
  position: relative;
  width: calc((100% - 36px) / 3);
  margin-bottom: 18px;
  cursor: pointer;
}
.gallery_item img {
  width: 100%;
}
.gallery_item_overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 189, 238, 0.7);
  opacity: 0;
  pointer-events: none;
  font-size: 33px;
  font-weight: 300;
  color: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.gallery_item:hover .gallery_item_overlay {
  opacity: 1;
}
.colorbox {
  outline: none;
}
.sidebar_tags {
  margin-top: 40px;
}
.tags_list li {
  display: inline-block;
  padding-left: 19px;
  padding-right: 18px;
  background: #f2f4f5;
  border-radius: 3px;
  margin-bottom: 10px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.tags_list li:not(:last-child) {
  margin-right: 5px;
}
.tags_list li:hover {
  background: #14bdee;
}
.tags_list li:hover a {
  color: #ffffff;
}
.tags_list li a {
  display: block;
  font-size: 12px;
  color: #76777a;
  text-transform: uppercase;
  line-height: 30px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.sidebar_banner {
  width: 100%;
  height: 213px;
}
.sidebar_banner_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.sidebar_banner_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #14bdee;
  opacity: 0.7;
}
.banner_title {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.75;
}
.banner_button {
  width: 200px;
  height: 46px;
  background: #ffffff;
  border-radius: 3px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.banner_button a {
  display: block;
  color: #14bdee;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-left: -0.1em;
  text-transform: uppercase;
  line-height: 46px;
  margin-top: 21px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.banner_button:hover {
  background: #14bdee;
}
.banner_button:hover a {
  color: #ffffff;
}

/*******************COURSE SPECIAlization******************/
.university_Sub_title {
  font-size: 1.1em;
  font-weight: 600;
  color: #777373;
}
.university_Sub_title span {
  padding-right: 6px;
}

/*******************explore-category PAGEn******************/
.explore-category {
  border-top: 1px dotted #aad7f0;
}
.filter-cat {
  margin: 1em 0;
}

.exp-cat-col {
  color: #fff;
  padding: 0;
  border: 5px solid #acbccd;
}

p.exp-course-title {
  line-height: 20px;
  font-size: 14px;
}

span.blink {
  font-size: 25px;
  font-family: cursive;
  color: white;
  animation: blink 3s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.exp-cat-col button {
  border-radius: 0;
}
/*
.e-course[data-cat="cat-red"] {
    background: red;
}
.e-course[data-cat="cat-green"] {
    background: green;
}
.e-course[data-cat="cat-blue"] {
    background:blue;
}*/

.filter-cat-results .e-course {
  opacity: 0;
  display: none;

  &.active {
    opacity: 1;
    display: block;
    -webkit-animation: fadeIn 0.65s ease forwards;
    animation: fadeIn 0.65s ease forwards;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1199px) {
  .sidebar {
    padding-left: 20px;
  }
  .review_rating {
    padding-left: 0;
  }
  .review_rating_bars {
    padding-left: 64px;
  }
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px) {
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {
}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
  .main_nav,
  .search_button {
    display: none;
  }
  .top_bar_contact_list li i,
  .top_bar_contact_list li > div,
  .login_button a {
    font-size: 12px;
  }
  .login_button {
    width: 140px;
  }
  .hamburger {
    display: inline-block;
    margin-left: 33px;
  }
  .course_col:not(:last-child) {
    margin-bottom: 40px;
  }
  .course_info {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .course_info_item:not(:last-child) {
    margin-bottom: 25px;
  }
  .course_info_item:not(:last-child)::after {
    display: none;
  }
  .review_rating_bars {
    padding-left: 134px;
  }
  .sidebar {
    margin-top: 100px;
    padding-left: 0;
    padding-top: 0;
  }
  .newsletter_container {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .newsletter_form_container {
    margin-top: 19px;
  }
  .footer_col:not(:last-child) {
    margin-bottom: 60px;
  }
  .footer_contact,
  .footer_links {
    padding-left: 0;
  }
  .footer_links ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .footer_mobile {
    float: none;
  }
  .footer_logo_container {
    margin-top: 0;
  }
  .copyright {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .cr_list {
    margin-top: 30px;
  }
  .cr_list li:not(:last-child) {
    margin-right: 20px;
  }
  .copyright div,
  .cr_list li a {
    font-size: 13px;
  }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {
}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {
}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {
}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {

  .top_bar {
    display: none;
  }
  .home {
    /*height: 140px;*/
  }
  .header.scrolled {
    top: 0px;
  }
  .review_rating {
    padding-top: 0;
    text-align: left;
  }
  .review_rating_bars {
    padding-left: 0;
    margin-top: 40px;
  }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 13px;
  }
  .menu {
    width: 100%;
    right: -100%;
  }
  .dropdown_item_select,
  .home_search_button {
    font-size: 12px;
  }
  .tab_panel_text p,
  .tab_panel_bullets li,
  .accordion_panel p {
    font-size: 13px;
  }
  .accordion div {
    font-size: 16px;
  }
  .tab {
    font-size: 12px;
  }
  .dropdown_item_title {
    font-size: 14px;
  }
  .dropdown_item_title span,
  .dropdown_item_title::before {
    font-size: 16px;
  }
  .dropdown_item_title::before {
    top: 0;
  }
  .has_children > .dropdown_item > .dropdown_item_title::after {
    font-size: 26px;
    top: -7px;
  }
  .dropdown_item_text p {
    font-size: 13px;
  }
  .review_rating_num {
    font-size: 56px;
  }
  .review_rating_stars {
    margin-top: 14px;
  }
  .review_rating_text {
    font-size: 14px;
    margin-top: 6px;
  }
  .comments_list li ul {
    padding-left: 30px;
  }
  .newsletter_form_container {
    width: 100%;
    padding-left: 0;
  }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {
}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {
}
@media only screen and (max-width: 1199px) {
  .course_info:first-child {
    margin-right: 12px;
  }
  .course_col:nth-child(odd) {
    padding-right: 15px;
  }
  .course_col:nth-child(even) {
    padding-left: 15px;
  }
  .sidebar {
    padding-left: 20px;
  }
  .gallery_item {
    width: calc((100% - 24px) / 3);
    margin-bottom: 12px;
  }
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px) {
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {
}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
  .main_nav,
  .search_button {
    display: none;
  }
  .top_bar_contact_list li i,
  .top_bar_contact_list li > div,
  .login_button a {
    font-size: 12px;
  }
  .login_button {
    width: 140px;
  }
  .hamburger {
    display: inline-block;
    margin-left: 33px;
  }
  .course_col:not(:last-child) {
    margin-bottom: 40px;
  }
  .sidebar {
    margin-top: 100px;
    padding-left: 0;
  }
  .newsletter_container {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .newsletter_form_container {
    margin-top: 19px;
  }
  .footer_col:not(:last-child) {
    margin-bottom: 60px;
  }
  .footer_contact,
  .footer_links {
    padding-left: 0;
  }
  .footer_links ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .footer_mobile {
    float: none;
  }
  .footer_logo_container {
    margin-top: 0;
  }
  .copyright {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .cr_list {
    margin-top: 30px;
  }
  .cr_list li:not(:last-child) {
    margin-right: 20px;
  }
  .copyright div,
  .cr_list li a {
    font-size: 13px;
  }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {
}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {
}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {
}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {
  .top_bar {
    display: none;
  }
  .home {
    height: 140px;
  }
  .header.scrolled {
    top: 0px;
  }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 13px;
  }
  .menu {
    width: 100%;
    right: -100%;
  }
  .dropdown_item_select,
  .home_search_button {
    font-size: 12px;
  }
  .newsletter_form_container {
    width: 100%;
    padding-left: 0;
  }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {
}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {
}

/************
13. 479px
************/

@media only screen and (max-width: 479px) {
}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {
}
/************
13. 479px
************/

@media only screen and (max-width: 479px) {
}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {
}

/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/
/*@media (min-width: 1200px){
	.row-cols-xl-6>* {
		flex: 0 0 auto;
		width: 16.66666667% !important;
	}
}
@media (min-width: 992px){
	.row-cols-lg-5>* {
		flex: 0 0 auto;
		width: 20% !important;
	}
}

@media (min-width: 768px){
	.row-cols-md-4>* {
		flex: 0 0 auto;
		width: 25%;
	}
}

@media (min-width: 576px)
	.row-cols-sm-3>* {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
}*/
/************
1. 1600px
************/

@media only screen and (max-width: 1600px) {
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px) {
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px) {
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px) {
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px) {
  .home_slider_prev {
    left: 25px;
  }
  .home_slider_next {
    right: 25px;
  }
  .course_info:first-child {
    margin-right: 12px;
  }
  .milestone:not(:last-child)::after {
    display: none;
  }
  .team_image {
    width: 160px;
    height: 140px;
  }
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px) {
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {
}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
  .main_nav,
  .search_button {
    display: none;
  }
  .top_bar_contact_list li i,
  .top_bar_contact_list li > div,
  .login_button a {
    font-size: 12px;
  }
  .login_button {
    width: 140px;
  }
  .hamburger {
    display: inline-block;
    margin-left: 33px;
  }
  .home_slider_form_container {
    width: 100%;
  }
  .home_search_button {
    margin-top: 15px;
  }
  .feature_col:not(:last-child) {
    margin-bottom: 30px;
  }
  .course_col:not(:last-child) {
    margin-bottom: 40px;
  }
  .counter_form {
    position: relative;
    top: 0;
    right: auto;
    width: auto;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .counter_form_title {
    margin-bottom: 15px;
  }
  .counter_content {
    padding-top: 100px;
    padding-bottom: 68px;
  }
  .counter {
    min-height: 0;
    padding-bottom: 0px;
  }
  .event_col:not(:last-child) {
    margin-bottom: 20px;
  }
  .team_col .team_item {
    padding-left: 0px;
    padding-right: 0px;
  }
  .team_col:first-child .team_item {
    padding-left: 0;
    padding-right: 0px;
  }
  .team_col:nth-child(4n) .team_item {
    padding-left: 0px;
    padding-right: 0;
  }
  .team_col:nth-child(odd) .team_item {
    padding-right: 6px;
  }
  .team_col:nth-child(even) .team_item {
    padding-left: 6px;
  }
  .news_col:not(:last-child) {
    margin-bottom: 80px;
  }
  .newsletter_container {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .newsletter_form_container {
    margin-top: 19px;
  }
  .footer_col:not(:last-child) {
    margin-bottom: 60px;
  }
  .footer_contact,
  .footer_links {
    padding-left: 0;
  }
  .footer_links ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .footer_mobile {
    float: none;
  }
  .footer_logo_container {
    margin-top: 0;
  }
  .copyright {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .cr_list {
    margin-top: 30px;
  }
  .cr_list li:not(:last-child) {
    margin-right: 20px;
  }
  .copyright div,
  .cr_list li a {
    font-size: 13px;
  }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {
}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {
}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {
}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {
  aside#mega-menu--mobile {
    display: block;
  }
  .top_bar {
    display: none;
  }
  .home {
    height: 470px;
  }

  .home_slider_content:before {
    position: absolute;
    content: "";
    background: #00000070;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  .content-wrapper {
    position: relative;
    z-index: 9;
    top: 12%;
  }

  .home_search_input {
    padding-left: 5px;
    width: 80%;
    height: 45px;
    border: 1px solid #cdcdcd;
    border-radius: 6px;
    font-size: 14px;
  }
  .wrap p {
    padding: 10px;
    border: 1px solid #cdcdcd;
    border-radius: 12px;
    text-align: left;
    margin: 8px 0;
  }
  button.home_search_button {
    width: 25%;
    margin: 0px 5px;
    border-radius: 6px;
    height: 45px;
  }
  .dropdown_item_select {
    padding-left: 7px;
    padding-right: 7px;
    background: #fff !important;
  }
  .home_slider_background {
    background-position: 70% 92%;
  }
  .home_slider_nav {
    display: none;
  }
  .home_slider_title {
    font-size: 28px;
    letter-spacing: 1px;
    color: #fff;
  }
  .home_slider_subtitle {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .mobile-serchbar .home-srchform {
    width: 100%;
    margin-left: 0%;
    height: 100%;
    padding: 0;
    background: transparent;
  }
  .desktop-serchbar {
    display: none !important;
  }

  .mobile-serchbar {
    display: block;
  }
  .home-amount {
    width: initial !important;
  }

  /****************/
  .Videowrap {
    background: #1c3b63;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .milestone:not(:last-child) {
    margin-bottom: 50px;
  }
  .team_col .team_item {
    padding-left: 0px;
    padding-right: 0px;
  }
  .team_col:first-child .team_item {
    padding-left: 0;
    padding-right: 0px;
  }
  .team_col:nth-child(4n) .team_item {
    padding-left: 0px;
    padding-right: 0;
  }
  .team_col:nth-child(odd) .team_item {
    padding-right: 0px;
  }
  .team_col:nth-child(even) .team_item {
    padding-left: 0px;
  }
  /**************/
  .Featured-University.section {
    padding-top: 20px;
  }
  .Featured-University .nav-tabs {
    padding: 10px 2px 10px;
  }
  .Featured-University .nav-tabs .nav-item {
    display: flex;
    margin: 0 2px;
    flex-direction: row;
    padding: 0;
  }
  .nav-link {
    padding: 0.5rem 0.7rem !important;
  }

  /*********/
  #top_university .fea_university_col {
    /*width: 92%;*/
    margin: 0 8px 0 9px;
  }
  .Top_university .fev-colleges {
    width: 90%;
  }
  .Top_university .owl-theme .owl-nav .owl-prev {
    left: -22px;
  }
  .Top_university .owl-theme .owl-nav .owl-next {
    right: 0px;
  }

  .gif-wrap {
    display: none;
  }
  #compare_university.owl-carousel {
    width: 100%;
  }
  .Compare .fev-colleges {
    width: 92%;
    margin: 0 auto;
  }
  .Compare .owl-theme .owl-nav .owl-prev {
    left: -7px;
  }
  .Compare .owl-theme .owl-nav .owl-next {
    right: -9px;
  }
  .University_blog.section {
    padding-top: 10px;
  }
  #University_news {
    margin: 0 auto;
  }
  #uni-expert {
    margin: 0 auto;
  }
  .expertwrap.fea_university_col {
    text-align: center;
    width: 87%;
    margin: 0 0;
    padding: 0 0;
  }
  .expertwrap .fev-colleges {
    width: 99%;
  }
  .Latest_Update.Featured-University .owl-theme .owl-nav .owl-prev {
    left: -23px;
  }
  .Latest_Update .owl-theme .owl-nav .owl-next {
    right: -23px;
  }

  #University_Blogs {
    margin: 0 auto;
  }
  .blogwrap.fea_university_col {
    width: 93%;
  }

  .University_blog .fev-colleges {
    width: 92%;
  }
  .University_blog .owl-theme .owl-nav .owl-next {
    right: 4px;
  }

  .Testimonials-wrap.section {
    padding-top: 20px;
  }
  #customers-testimonials .item {
    padding: 20px 20px 15px;
    margin-left: -5%;
  }
  .connsult-wrap .modal-dialog {
    max-width: 100% !important;
  }
  .modal-body.consult-body {
    padding-left: 0;
    padding-right: 0;
  }
  .connsult-wrap .modal-title {
    margin-top: 0px;
    margin-bottom: 0;
    text-align: center;
    font-size: 17px;
  }
  .connsult-wrap.corseimg-popup {
    width: 100% !important;
  }
  .course-feeimg.consult-img {
    display: block;
  }

  .consult-img {
    display: none;
  }
  .comment_form > div:not(:last-child) {
    margin-bottom: 15px;
  }
  .student-box {
    height: 300px;
    width: 100%;
    margin-left: -3%;
  }
  .student-box h6.student-mannse-Title {
    font-size: 20px;
  }
  .events {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .events .section_title {
    margin-bottom: 20px;
  }
  .event {
    width: 93%;
    margin: 0px 0 14px 0%;
  }
  .events .owl-theme .owl-nav .owl-prev {
    left: -11px !important;
  }
  .events .owl-theme .owl-nav .owl-next {
    right: -11px !important;
  }
  .newsletter {
    height: 470px;
  }
  .newsletter_form_container img {
    width: 104%;
  }
  .university_education {
    background: #27558f;
    margin-top: 0.3rem;
  }
  .university_education p {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
  }

  .university_location h4 {
    height: 35px;
    margin-top: 8px;
    font-size: 1.1rem;
    color: #fff;
  }
  .btn-shine {
    font-size: 1.1rem;
  }
  .university_location.border-right {
    border-right: 2px dotted #e2e2e2;
  }

  .ugall-university {
    min-height: 200px !important;
  }

  .university {
    min-height: 220px;
  }
  .university_title {
    font-size: 25px;
    min-height: auto;
    line-height: 30px;
  }
  .university_info {
    width: 100%;
    height: 60px;
    margin-top: 18px;
  }

  .home.university {
    height: 245px !important;
  }
  .university_info_item {
    width: 100%;
    padding-left: 20px;
  }
  .compair_wrap_breadcrumb .university_title {
    font-size: 24px;
    min-height: auto;
    line-height: 30px;
  }
  .compare_details {
    padding: 10px 0;
  }
  .spec-tital-box .title a {
    height: auto;
  }

  /********************NEW CSS 8-7-2024 START********************/
  .sub_header {
    display: block;
    margin-bottom: 5px;
  }
  .sub_header .nav-scroller {
    padding-bottom: 5px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    overflow-x: scroll;
  }
  .sub_header .nav-scroller-wrapper {
    padding: 11px 12px 5px;
    background: #e1e6e9;
    margin: 10px 15px;
    border-radius: 8px;
    border-bottom: 5px solid #173863;
  }
  .nav-scroller-btn {
    font-size: 1.55rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    width: 24px !important;
    height: 24px !important;
  }
  .nav-scroller-btn--left {
    left: 6px !important;
  }

  .sub_header .nav-scroller-wrapper .nav-scroller-btn {
    display: none;
  }
  .nav-scroller-content .nav-scroller-item {
    color: #191c1f !important;
  }
  .nav-scroller-content .nav-scroller-item.active {
    background: #27558f !important;
    margin-bottom: 10px;
    color: #fff !important;
    border-radius: 6px;
    padding: 14px 6px;
  }

  /********************NEW CSS 8-7-2024 END*********************/

  .internal_box.student-box {
    margin-left: 3px;
  }
  .university_info_item.review-wrap {
    margin-top: 6%;
  }

  .university_banner_wrap .owl-theme .owl-nav {
    top: 23% !important;
  }
  .university_video_main {
    width: 100%;
    border-radius: 6px;
    border: 3px solid #ff9800;
    margin-bottom: 0.5rem;
  }
  .university_video_main a {
    display: inline-block;
    margin-top: -4px;
  }

  .key_box {
    height: 90px;
  }

  /************************/
  .headingss .poffer.pofferone,
  .contentss .poffer.pofferone {
    width: 75%;
  }
  .headingss .poffer.poffertwo,
  .contentss .poffer.poffertwo {
    width: 25%;
    text-align: right;
  }
  .headingss .full-width {
    display: none;
  }
  .contact-university-now {
    padding: 5px;
    width: 105%;
  }

  /*************************************/
  .compare_feilds {
    padding: 10px 7px;
  }
  .compare-img img {
    max-width: 81px;
  }
  .compare-text {
    font-size: 14px;
    line-height: 17px;
  }
  .compare_feilds .edu-mode {
    padding: 15px 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    text-align: center;
  }
  li.edu-mode.sample-certificate {
    min-height: 160px;
  }
  .edu-mode.sample-certificate img {
    width: 100%;
  }

  /*************/
  /*.university.best_colleges {
		min-height: 100%;
		padding-bottom: 20px;
	}*/
  .university.best_colleges .university_title {
    padding-left: 1.7%;
    color: #1b5bac;
    font-size: 1.7em;
    border-left: 5px double #ff9800;
    min-height: auto;
  }
  .ol-detail-box {
    padding-left: 0%;
  }

  a.watch_video {
    margin: 0 15px 0 0;
    padding: 10px 9px;
  }

  /************************************/
  .university.Blogs_title {
    padding-bottom: 10px;
  }
  .blog_title {
    font-size: 22px;
  }

  .sidebar_section.sidebar-item {
    display: none;
  }
  .sidebar_section:not(:last-child) {
    margin-bottom: 25px;
  }
  .course {
    padding-bottom: 25px;
  }
  .special_boxes {
    padding: 20px 0px;
    box-shadow: none;
  }

  .specialization_wrap {
    width: 99%;
  }
  .spec-title {
    margin: 1.5em 0;
  }

  .spec-title h4 {
    font-size: 1em;
    line-height: 22px;
  }
  .ShortDesContent {
    display: none;
  }
  .gsc-title-wrap .linergrade {
    font-size: 1.5rem;
  }
  .gsc-title-wrap h2.modal-title {
    font-size: 1.1em;
    line-height: 24px;
  }
  .spec-le-img {
    position: relative;
    display: none;
  }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
  }
  .menu {
    width: 100%;
    right: -100%;
  }
  .home_search_input::-webkit-input-placeholder {
    font-size: 12px !important;
  }
  .home_search_input:-moz-placeholder {
    font-size: 12px !important;
  }
  .home_search_input::-moz-placeholder {
    font-size: 12px !important;
  }
  .home_search_input:-ms-input-placeholder {
    font-size: 12px !important;
  }
  .home_search_input::input-placeholder {
    font-size: 12px !important;
  }
  .dropdown_item_select,
  .home_search_button {
    font-size: 12px;
  }
  .home_search_button {
    width: 100px;
    height: 40px;
  }
  .nav-link {
    padding: 0.5rem 0.3rem !important;
  }

  #top_university .fea_university_col {
    width: 96.3%;
    margin: 0 8px 0 2px;
  }
  .Top_university .fev-colleges {
    width: 90%;
  }
  .Top_university .owl-theme .owl-nav .owl-prev {
    left: -22px;
  }

  .Compare .fea_university_col {
    width: 95.5%;
  }

  .blogwrap.fea_university_col {
    width: 90%;
  }

  .student-box {
    height: 300px;
    width: 92%;
    margin-left: -4%;
  }
  .event {
    width: 90%;
    margin: 0px 0 14px -1%;
  }

  .newsletter_form_container {
    width: 100%;
    padding-left: 0;
  }
  /***********/
  .university.best_colleges {
    min-height: 100%;
    padding-bottom: 20px;
  }
  .ol-detail-box {
    padding-left: 0%;
  }

  a.watch_video {
    margin: 0 15px 0 0;
    padding: 10px 9px;
  }

  .explore-category .university_title {
    font-size: 2em;
  }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {
}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {
  #top_university .fea_university_col {
    width: 96.3%;
    margin: 0 8px 0 2px;
  }
  .Top_university .fev-colleges {
    width: 90%;
  }
  .Top_university .owl-theme .owl-nav .owl-prev {
    left: -22px;
  }

  .Compare .fea_university_col {
    width: 95.5%;
  }

  .blogwrap.fea_university_col {
    width: 98%;
  }

  .student-box {
    height: 300px;
    width: 94%;
    margin-left: -4%;
  }
  .event {
    width: 90%;
    margin: 0px 0 14px -1%;
  }

  .newsletter_form_container {
    width: 100%;
    padding-left: 0;
  }

  #e_loan a {
    height: 107px;
    line-height: 25px;
  }
 

  #accordionExample .collapsible-link::before {
    right: -5px;
  }

  /***********/
  .university.best_colleges {
    min-height: 100%;
    padding-bottom: 20px;
  }
  .ol-detail-box {
    padding-left: 0%;
  }

  a.watch_video {
    margin: 0 15px 0 0;
    padding: 10px 9px;
  }
  .advice_wrap #e_loan a {
    height: 100px;
  }
  .expert-btn.advice_wrap #e_loan a {
    height: auto;
  }

  /********************NEW CSS 8-7-2024 START********************/
  /*.sub_header {
		display: block;
        margin-bottom: 5px;
	}
	.sub_header .nav-scroller {
		padding-bottom: 5px;
		margin-bottom: 0px;
		margin-right: 11px;
		margin-left: 11px;
	}
	.sub_header .nav-scroller-wrapper {
		padding: 5px 30px 5px;
	}
	.nav-scroller-btn {
		 font-size: 1.55rem !important; 
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-top: 0px !important;  
		width: 24px !important;
		height: 24px !important;
	}
	.nav-scroller-btn--left {
		left: 6px !important;
	}
	.nav-scroller-btn{dispay:none  !important;}
	*/
  /********************NEW CSS 8-7-2024 END*********************/
}

/************
13. 479px
************/

@media only screen and (max-width: 479px) {
}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {
}
